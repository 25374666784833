<template>
  <div v-show="showSiteHeader">
    <div class="spacer" :style="{ height: `${dockHeight}px` }"></div>

    <header ref="root" class="site-header" :class="{ dock, float }">
      <div class="site-header-content">
        <a :href="rootUrl">
          <img class="logo" src="app/assets/images/logo.png" alt="TourHero Logo">
        </a>

        <HeaderLinks v-if="desktop" :float/>

        <HeaderMenu :float @logout="logout()"/>
      </div>
    </header>
  </div>
</template>

<script setup>
import api from '@/utils/api.js';
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useStyleTag } from "@vueuse/core";
import { useShared } from "@/helpers/vueHelpers.js";
import { useSiteHeaderStore } from "@/stores/siteHeader.js";
import { useElementSize, useWindowScroll } from "@vueuse/core";
import { metaTagContent } from "@/helpers/documentHelpers.js";
import HeaderLinks from "@/modules/Header/HeaderLinks.vue";
import HeaderMenu from "@/modules/Header/HeaderMenu.vue";
import { useGlobalState } from "@/stores/global.js";

const root = ref(null);
const dock = ref(true);
const float = ref(false);
const { y } = useWindowScroll()
const { height } = useElementSize(root);
const dockHeight = ref(0);
const rootUrl = metaTagContent('menuRootUrl');
const logoutUrl = metaTagContent('menuLogoutUrl');
const { css } = useStyleTag();
const { desktop } = useShared();
const { forceShow } = storeToRefs(useSiteHeaderStore());
const { showSiteHeader, siteHeaderHeight } = useGlobalState();
import { setBodyCssVar } from "@/helpers/vueHelpers.js";

watch([y, forceShow], ([newY, show], [oldY]) => {
  dock.value = newY <= dockHeight.value;
  float.value = !dock.value && (newY <= oldY || show);
});
watch([dock, height], ([dock, height]) => {
  dockHeight.value = dock ? (height + 2) : dockHeight.value;
  css.value = `.flash { margin-top: ${dockHeight.value}px; }`;
});
watch(height, (h) => {
  siteHeaderHeight.value = h;
  setBodyCssVar('--site-header-height', `${h}px`);
});

async function logout() {
  try {
    await api.delete(logoutUrl);
  } catch (err) {
    console.error(err);
  } finally {
    window.location.replace(rootUrl);
  }
}
</script>

<style lang="scss" scoped>
.site-header {
  @include main-container;
  @include main-wrapper;
  position: fixed;
  z-index: 2900;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  transition: transform 200ms linear;

  @include mobile {
    border-bottom: 2px solid #F6F6F6;
    padding: 0;

    .site-header-content {
      padding: 10px 20px 10px 24px !important;
      border-radius: 0;
    }
  }
}

.site-header.dock {
  transform: translateY(0);
  border-bottom: 2px solid #F6F6F6;
  background-color: white;

  .site-header-content {
    padding: 20px 0;
    box-shadow: none;
  }
}

.site-header.float {
  @include desktop {
    transform: translateY(15px);
    border-bottom: none;
  }

  @include mobile {
    transform: translateY(0);
  }
}

.site-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.70);
  backdrop-filter: blur(20px);
  box-shadow: 0 2px 12px 0 rgba(34, 34, 34, 0.10);

  .logo {
    height: 32px;
    // visually center
    position: relative;
    top: -4px;

    @include mobile {
      height: 24px;
      top: -2.5px;
    }
  }
}
</style>
